// import { allapiAction } from "@/Redux/common/action";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoaderComponent from "../Loader";
import OurWork from "../OurWork/OurWork";
import OurWork2 from "../OurWork/OurWork2";
import Category from "./Category";
import HomeSlider from "./HomeSlider";
import Listing from "./Listing";
import Listors from "./Listors";
import NavigateBtn from "./NavigateBtn";
import Product from "./Product";
import RecentProduct from "./RecentProduct";
import Video from "./Video";
import Winner from "./Winner";
import { allapiAction } from "../../Redux/common/action";
import { URL } from "../../Redux/common/url";
import axios from "axios";
import Seo from "../Seo/Seo";
import FeProduct from "./FeProduct";
import Branch from "../OurWork/Branch";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "react-router-dom";
import CoustomImg from "../CoustomImg/CoustomImg";
import TruncateText from "../TruncateText/TruncateText";

function HomeComponent() {
  const dispatch = useDispatch();

  const get_marques_List = useSelector((state) =>
    state?.allapi?.get_marques_List ? state?.allapi?.get_marques_List : {}
  );

  const get_home_all_pro = useSelector((state) =>
    state?.allapi?.get_home_all_pro ? state?.allapi?.get_home_all_pro : {}
  );

  const news = get_marques_List?.news ? get_marques_List?.news : [];
  const admq = get_marques_List?.ads ? get_marques_List?.ads : [];
  const about = get_marques_List?.about ? get_marques_List?.about : [];

  useEffect(() => {
    dispatch(allapiAction.gethomePro({}));
    dispatch(allapiAction.getsliderList({ page: "home" }));
    dispatch(allapiAction.getMarqueesList2({ page: "home" }));
  }, []);

  const handleCreateOrder = async () => {
    try {
      const response = await axios.post(
        URL?.API_BASE_URL + "/publicApi/createOrdersq",
        {
          customerMobile: "9636996332",
          amount: "1",
          orderId: "testmrncxavfrrajm",
          redirectUrl: "http://localhost:6001",
          remark1: "test",
          remark2: "testsdt",
        }
      );

      if (response.data.status) {
        window.location.href = response.data.payment_url;
      } else {
        alert("Failed to create order");
      }
    } catch (error) {
      console.error("Error creating order:", error);
      alert("An error occurred. Please try again.");
    }
  };

  // const [products, setProducts] = useState([]);
  // const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     if (
  //       window.innerHeight + window.scrollY >=
  //         document.body.offsetHeight - 5000 &&
  //       !loading
  //     ) {
  //       setPage((prevPage) => prevPage + 1);
  //     }
  //   };
  //   window.addEventListener("scroll", handleScroll);

  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, [loading]);

  // console.log(page);
  const fetchProducts2 = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        URL.API_BASE_URL + `/publicApi/gethomepageOnProduct?page=${page}`
      );
      const newProducts2 = await response.json();
      console.log(newProducts2);
      const newProducts = newProducts2?.data?.docs || [];
      setProducts((prevProducts) => [...prevProducts, ...newProducts]);
    } catch (error) {
      console.error("Failed to fetch products", error);
    }
    setLoading(false);
  };

  // useEffect(() => {
  //   fetchProducts();
  // }, [page]);

  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    // Load the initial data
    fetchProducts();
  }, []);
  function delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  const fetchProducts = async () => {
    try {
      await delay(500);
      const response = await fetch(
        `${URL.API_BASE_URL}/publicApi/gethomepageOnProduct?page=${page}`
      );
      const newProducts2 = await response.json();
      const newProducts = newProducts2?.data?.docs || [];
      // If no products are returned, set hasMore to false
      if (newProducts.length === 0) {
        setHasMore(false);
      }

      // Update products and increment page number
      setProducts((prevProducts) => [...prevProducts, ...newProducts]);
      setPage((prevPage) => prevPage + 1);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };
  const [windowWidth, setWindowWidth] = useState(600);

  // Update window width when the window is resized
  useLayoutEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div>
      <Seo
        settitle={"orafame"}
        titleSeo={"orafame"}
        seoKeyword={"orafame"}
        seo_descriptions={"orafame"}
        seoconcal={"https://orafame.com/"}
        title={"orafame"}
      />
      {Object.keys(get_home_all_pro)?.length > 0 ? (
        <div className="hometest">
          <div className="marquee relative">
            {/* <img
              src="/img/JOBPORTAL.png"
              alt="BeestBharatmessage.png"
              width={29}
              style={{
                position: "absolute",
                top: "0px",
                left: "0px",
                zIndex: "1",
              }}
            /> */}
            <marquee behavior="scroll" direction="left" scrollamount="05">
              {about &&
                about?.map((data, i) => {
                  return <span key={data._id}>{data?.title}</span>;
                })}
            </marquee>
          </div>

          {/* <button
            onClick={() => {
              handleCreateOrder();
            }}
          >
            pay
          </button> */}
          {/* <OurWork2 homedata={get_home_all_pro} /> */}
          {/* <div className="marquee">
            <marquee behavior="scroll" direction="left" scrollamount="05">
              {about &&
                about?.map((data, i) => {
                  return <span key={data._id}>{data?.title}</span>;
                })}
            </marquee>
          </div> */}

          <div className="">
            <HomeSlider />
          </div>

          {/* coment only for now */}
          {/* <div className="marquee mt-5">
            <marquee behavior="scroll" direction="left" scrollamount="05">
              {admq &&
                admq?.map((data, i) => {
                  return <span key={data._id}>{data?.title}</span>;
                })}
            </marquee>
          </div> */}
          {/* <NavigateBtn homedata={get_home_all_pro} /> */}
          {/* <Product homedata={get_home_all_pro} /> */}
          <Category homedata={get_home_all_pro} />
          {/* <Listing homedata={get_home_all_pro} /> */}
          <FeProduct homedata={get_home_all_pro} />
          <RecentProduct homedata={get_home_all_pro} />

          {/* <Winner homedata={get_home_all_pro} /> */}
          {news?.length > 0 && (
            <div className="  ">
              <div className="mark_bg mt-2">
                <div className="headertext">Latest Notification</div>
                <div className="news">
                  <marquee>
                    {news &&
                      news?.map((data, i) => {
                        return <span key={data._id}>{data?.title}</span>;
                      })}
                  </marquee>
                </div>
              </div>
            </div>
          )}

          <div className=" ">
            <Video homedata={get_home_all_pro} />
            {/* <Listors homedata={get_home_all_pro} /> */}

            {/* <OurWork homedata={get_home_all_pro} /> */}
            {/* <Branch homedata={get_home_all_pro} /> */}
          </div>


          <div className="container-fluid">
            <div className="ps-deal-of-day shewBox">
              <div className="ps-container">
                <div className="ps-section__header">
                  <div className="ps-block--countdown-deal">
                    <div className="ps-block__left">
                      <b>
                        {" "}
                        <h3>All Product</h3>
                      </b>
                    </div>
                  </div>
                  <Link to="/products">View all</Link>
                </div>
                <div className="ps-section__content mt-10">
                  <InfiniteScroll
                    dataLength={products.length} // Length of data loaded so far
                    next={fetchProducts} // Function to load more data
                    hasMore={hasMore} // Controls when to stop loading more
                    loader={
                      <div>
                        <div className="spinnerContainer">
                          {/* <div class="spinner">
                            <span>L</span>
                            <span>O</span>
                            <span>A</span>
                            <span>D</span>
                            <span>I</span>
                            <span>N</span>
                            <span>G</span>
                          </div> */}
                          <section class="dots-container">
                            <div class="dot"></div>
                            <div class="dot"></div>
                            <div class="dot"></div>
                            <div class="dot"></div>
                            <div class="dot"></div>
                          </section>
                        </div>
                      </div>
                    } // Loading message/component
                    endMessage={<p> </p>}
                  >
                  <div className="container-fluid"> 
                  <div className="row">
                      {products.map((data, index) => (
                        <div
                          className="col-xl-2 col-lg-3 col-md-3 col-sm-6 col-6 mb-2"
                          key={index}
                        >
                          <div className="recentlostfound">
                            <div className="ps-product">
                              <div className="ps-product__thumbnail  ps-product__thumbnailms2">
                                <Link
                                  to={
                                    data?.typeof == "Listing"
                                      ? "/listing/" + data?.pro_sulg
                                      : "/product/" + data?.pro_sulg
                                  }
                                >
                                  <CoustomImg
                                    url={URL.API_BASE_URL + data?.featureImage}
                                    altkey={data?.title}
                                    w1={200}
                                    w2={250}
                                    h1={160}
                                    h2={220}
                                  />
                                </Link>
                              </div>
                              <div className="ps-product__container">
                                <div className="ps-product__content">
                                  <Link
                                    className="ps-product__title"
                                    to={
                                      data?.typeof == "Listing"
                                        ? "/listing/" + data?.pro_sulg
                                        : "/product/" + data?.pro_sulg
                                    }
                                  >
                                    <TruncateText
                                      text={data?.title}
                                      maxLength={windowWidth > 600 ? 25 : 15}
                                    />
                                    {/* {data?.title} */}
                                  </Link>

                                  {data?.afterdiscountprice && (
                                    <p className="ps-product__price sale">
                                      ₹{data?.afterdiscountprice}{" "}
                                      <del>₹{data?.price} </del>
                                    </p>
                                  )}
                                  <Link
                                    className="ps-product__vendor"
                                    to={"/vendor-store/" + data?.sellerId?._id}
                                  >
                                    {data?.sellerId?.displayName}
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                   </div>

                    
                  </InfiniteScroll>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <LoaderComponent />
      )}
      <hr />
    </div>
  );
}

export default HomeComponent;
