import { UsergroupDeleteOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
// import { useRouter } from "next/router";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { useNavigate } from "react-router-dom";

const Boxes3 = () => {
  const navigate = useNavigate();
  const deshboard = useSelector((state) =>
    state?.allapi?.deshboard ? state?.allapi?.deshboard : {}
  );
console.log(deshboard);
const get_profile = useSelector((state) =>
  state?.allapi?.get_profile ? state?.allapi?.get_profile : {}
);

const totalIncome = 
deshboard?.bounseIncomes +   
deshboard?.directincome +   
deshboard?.gapingIncomes +   
deshboard?.lavelIncomes +   
deshboard?.matchingIncomes +   
deshboard?.reMatchingIncomes +   
deshboard?.redirectincome  

  return (
    <Row gutter={[0, 20]}>
        <Col lg={6} md={12} sm={12} xs={12}>
          <div
            className="flex1 p-10 userd userd3 m-5 cursor-pointer"
            onClick={() => navigate("/accounts/direct-team")}
          >
            <div className="flex justify-content-between align-item-center">
              <div className="relative boxHeadingRisk fw-600">{deshboard?.DirectTeam? deshboard?.DirectTeam: 0}</div>
            </div>
            <div className="flex justify-content-end align-item-center">
              <div className="dashbordTextnew">Direct Team</div>
            </div>
          </div>
        </Col>
        <Col lg={6} md={12} sm={12} xs={12}>
          <div
            className="flex1 p-10 userd userd4   m-5 cursor-pointer"
            onClick={() => navigate("/accounts/total-team")}
          >
            <div className="flex justify-content-between align-item-center">
              <div className="relative boxHeadingRisk fw-600">{deshboard?.allTeam2? deshboard?.allTeam2: 0}</div>
            </div>
            <div className="flex justify-content-end align-item-center">
              <div className="dashbordTextnew">Total Team</div>
            </div>
          </div>
        </Col>


        <Col lg={6} md={12} sm={12} xs={12}>
          <div
            className="flex1 p-10  userd userd1  m-5 cursor-pointer"
            // onClick={() => navigate("/generate-certificate")}
          >
            <div className="flex justify-content-between align-item-center">
              <div className="relative boxHeadingTotal fw-600">
                {/* Certificates */}
                {get_profile?.lefttotalTeams ? get_profile?.lefttotalTeams: 0}
              </div>
            </div>
            <div className="flex justify-content-end align-item-center">
              <div className="dashbordTextnew">Left Team</div>
            </div>
          </div>
        </Col>
        <Col lg={6} md={12} sm={12} xs={12}>
          <div
            className="flex1 p-10  userd userd2 m-5 cursor-pointer"
            // onClick={() => navigate("/student")}
          >
            <div className="flex justify-content-between align-item-center">
              <div className="relative boxHeadingRisk fw-600">    {get_profile?.righttotalTeams ? get_profile?.righttotalTeams: 0}</div>
            </div>
            <div className="flex justify-content-end align-item-center">
              <div className="dashbordTextnew">Right Team</div>
            </div>
          </div>
        </Col>

  {/* new team */}


  <Col lg={6} md={12} sm={12} xs={12}>
          <div
            className="flex1 p-10 userd userd5  m-5 cursor-pointer"
            // onClick={() => navigate("/users?page=1&status=2")}
          >
            <div className="flex justify-content-between align-item-center">
              <div className="relative boxHeadingTotal fw-600">   {get_profile?.leftdireactTeams  ? get_profile?.leftdireactTeams : 0}</div>
            </div>
            <div className="flex justify-content-end align-item-center">
              <div className="dashbordTextnew">Direct Left Team</div>
            </div>
          </div>
        </Col>
        <Col lg={6} md={12} sm={12} xs={12}>
          <div
            className="flex1 p-10 userd userd6 m-5 cursor-pointer"
            // onClick={() => navigate("/users?page=1&status=3")}
          >
            <div className="flex justify-content-between align-item-center">
              <div className="relative boxHeadingRisk fw-600">    {get_profile?.rightdireactTeams ? get_profile?.rightdireactTeams: 0}</div>
            </div>
            <div className="flex justify-content-end align-item-center">
              <div className="dashbordTextnew">Direct Right Team</div>
            </div>
          </div>
        </Col>
        <Col lg={6} md={12} sm={12} xs={12}>
          <div
            className="flex1 p-10 userd userd3  m-5 cursor-pointer"
            //   onClick={() => navigate("/users")}
          >
            <div className="flex justify-content-between align-item-center">
              <div className="relative boxHeadingTotal fw-600">
                {" "}
                {totalIncome ? totalIncome: 0}
              </div>
            </div>
            <div className="flex justify-content-end align-item-center">
              <div className="dashbordTextnew">Total Income</div>
              {/* <UsergroupDeleteOutlined /> */}
            </div>
          </div>
        </Col>
        <Col lg={6} md={12} sm={12} xs={12}>
          <div
            className="flex1 p-10  userd userd4  m-5 cursor-pointer"
            onClick={() => navigate("/accounts/direct-income")}
          >
            <div className="flex justify-content-between align-item-center">
              <div className="relative boxHeadingRisk fw-600">
                {/* Add Lost And Found */}
                {deshboard?.directincome ? deshboard?.directincome: 0}
              </div>
            </div>
            <div className="flex justify-content-end align-item-center">
              <div className="dashbordTextnew">
                {/* {deshboard?.completeOrder} */}
            Direct Income
              </div>
            </div>
          </div>
        </Col>
        <Col lg={6} md={12} sm={12} xs={12}>
          <div
            className="flex1 p-10  userd userd1  m-5 cursor-pointer"
            onClick={() => navigate("/accounts/bonus-income")}
          >
            <div className="flex justify-content-between align-item-center">
              <div className="relative boxHeadingTotal fw-600">
                {/* Certificates */}
                {deshboard?.bounseIncomes ? deshboard?.bounseIncomes: 0}
              </div>
            </div>
            <div className="flex justify-content-end align-item-center">
              <div className="dashbordTextnew">Bonus Incomes</div>
            </div>
          </div>
        </Col>
        <Col lg={6} md={12} sm={12} xs={12}>
          <div
            className="flex1 p-10  userd userd2 m-5 cursor-pointer"
            onClick={() => navigate("/accounts/level-income")}
          >
            <div className="flex justify-content-between align-item-center">
              <div className="relative boxHeadingRisk fw-600">    {deshboard?.lavelIncomes ? deshboard?.lavelIncomes: 0}</div>
            </div>
            <div className="flex justify-content-end align-item-center">
              <div className="dashbordTextnew">Level Income</div>
            </div>
          </div>
        </Col>
        <Col lg={6} md={12} sm={12} xs={12}>
          <div
            className="flex1 p-10 userd userd5  m-5 cursor-pointer"
            onClick={() => navigate("/accounts/matching-income")}
          >
            <div className="flex justify-content-between align-item-center">
              <div className="relative boxHeadingTotal fw-600">   {deshboard?.matchingIncomes ? deshboard?.matchingIncomes: 0}</div>
            </div>
            <div className="flex justify-content-end align-item-center">
              <div className="dashbordTextnew">Matching Income</div>
            </div>
          </div>
        </Col>
        <Col lg={6} md={12} sm={12} xs={12}>
          <div
            className="flex1 p-10 userd userd6 m-5 cursor-pointer"
            onClick={() => navigate("/accounts/redirect-income")}
          >
            <div className="flex justify-content-between align-item-center">
              <div className="relative boxHeadingRisk fw-600">    {deshboard?.redirectincome ? deshboard?.redirectincome: 0}</div>
            </div>
            <div className="flex justify-content-end align-item-center">
              <div className="dashbordTextnew">Repurchase Direct</div>
            </div>
          </div>
        </Col>
        <Col lg={6} md={12} sm={12} xs={12}>
          <div
            className="flex1 p-10 userd userd7 m-5 cursor-pointer"
            onClick={() => navigate("/accounts/relevel-income")}
          >
            <div className="flex justify-content-between align-item-center">
              <div className="relative boxHeadingTotal fw-600"> {deshboard?.gapingIncomes? deshboard?.gapingIncomes: 0}</div>
            </div>
            <div className="flex justify-content-end align-item-center">
              <div className="dashbordTextnew">Gapping Income</div>
            </div>
          </div>
        </Col>
        <Col lg={6} md={12} sm={12} xs={12}>
          <div
            className="flex1 p-10 userd userd8 m-5 cursor-pointer"
            onClick={() => navigate("/accounts/rematching-income")}
          >
            <div className="flex justify-content-between align-item-center">
              <div className="relative boxHeadingRisk fw-600">{deshboard?.reMatchingIncomes? deshboard?.reMatchingIncomes: 0}</div>
            </div>
            <div className="flex justify-content-end align-item-center">
              <div className="dashbordTextnew">Repurchase Matching </div>
            </div>
          </div>
        </Col>


    
      {/* <Col lg={6} md={12} sm={12} xs={12}>
        <div
          className="flex1 p-10 userd userd7 m-5 cursor-pointer"
          // onClick={() => navigate("/users?page=1&status=4")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600"> {get_profile?.gapingIncomes? get_profile?.gapingIncomes: 0}</div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="dashbordTextnew">Gapping Income</div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={12} sm={12} xs={12}>
        <div
          className="flex1 p-10 userd userd8 m-5 cursor-pointer"
          // onClick={() => navigate("/users?page=1&status=5")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingRisk fw-600">{get_profile?.reMatchingIncomes? get_profile?.reMatchingIncomes: 0}</div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="dashbordTextnew">Repurchase Matching </div>
          </div>
        </div>
      </Col> */}


      {/* <Col lg={6} md={12} sm={12} xs={12}>
        <div
          className="flex1 p-10 userd userd1  m-5 cursor-pointer"
          //   onClick={() => navigate("/users")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600">
              Today Company Busi.
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="dashbordTextnew">{deshboard?.companyBusiness}</div>
          </div>
        </div>
      </Col> */}
      {/* <Col lg={6} md={12} sm={12} xs={12}>
        <div
          className="flex1 p-10  m-5 cursor-pointer"
          //   onClick={() => navigate("/users")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingRisk fw-600">
              Today Comp. Busi. With GST
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="dashbordTextnew">
              {deshboard?.companyBusinessWithGst}
            </div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={12} sm={12} xs={12}>
        <div
          className="flex1 p-10  m-5 cursor-pointer"
          //   onClick={() => navigate("/category")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600">
              Total Stock Amount
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="dashbordTextnew">
              {deshboard?.totalStockProductAmount}
            </div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={12} sm={12} xs={12}>
        <div
          className="flex1 p-10  m-5 cursor-pointer"
          onClick={() => navigate("/gpg-ct")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingRisk fw-600">Gpg ct</div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="dashbordTextnew">{deshboard?.gpgct}</div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={12} sm={12} xs={12}>
        <div
          className="flex1 p-10  m-5 cursor-pointer"
          onClick={() => navigate("/gpg-mq")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600">Gpg mq</div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="dashbordTextnew">{deshboard?.gpgmq}</div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={12} sm={12} xs={12}>
        <div
          className="flex1 p-10  m-5 cursor-pointer"
          onClick={() => navigate("/gpg-duqe")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingRisk fw-600">Gpg duqe</div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="dashbordTextnew">{deshboard?.gpgduqe}</div>
          </div>
        </div>
      </Col> */}
      {/* <Col lg={6} md={12} sm={12} xs={12}>
        <div
          className="flex1 p-10  m-5 cursor-pointer"
        //   onClick={() => navigate("/category")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600">
              Total admin commission
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="dashbordTextnew">{deshboard?.adminWinCommission}</div>
          </div>
        </div>
      </Col> */}

      {/* <Col span={6}>
        <div
          className="flex1 p-10 dashboardBox dashboardBoxShad2 cursor-pointer"
          onClick={() => navigate("/question")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600">
              Total Question
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="dashbordTextnew">{deshboard?.total_question || 0}</div>
          </div>
        </div>
      </Col> */}
    </Row>
  );
};

export default Boxes3;
