import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
// import { URL } from "@/Redux/common/url";
// import Api from "@/Redux/common/api";
// import { useRouter } from "next/router";

import { useDispatch, useSelector } from "react-redux";
// import { allapiAction } from "@/Redux/common/action";
import Slider from "react-slick";
import { URL } from "../../Redux/common/url";
import Api from "../../Redux/common/api";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { allapiAction } from "../../Redux/common/action";
import { AsyncPaginate } from "react-select-async-paginate";
import HomeSlider from "../Home/HomeSlider";
import RegisaterSlider from "../Home/RegisaterSlider";
function RefRegisterComponent() {
  const [type, settype] = useState("User");
  const [userData, setuserData] = useState({
    fullName: "",
    password: "",
    country: "India",
  });
  const route = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const pagev = searchParams.get("page");
  const ref = searchParams.get("ref");
  const club = searchParams.get("club");
  const searchData = searchParams.get("search");

  console.log(location);

  const [otp_box, setotpBox] = useState("0");

  const slider_list = useSelector((state) =>
    state?.allapi?.slider_list ? state?.allapi?.slider_list : {}
  );

  const dispatch = useDispatch();

  console.log(slider_list);
  const slider = slider_list?.sliders ? slider_list?.sliders : [];
  const ad = slider_list?.ads ? slider_list?.ads : [];
  const banner = slider_list?.banner ? slider_list?.banner : [];
  const ads2 = [...slider].reverse();

  console.log(slider);
  console.log(ads2);

  useEffect(() => {
    if (ref) {
      hendlereferCheck(ref);
    }

    return () => {};
  }, [location.search]);
  const [referName, setreferName] = useState("");
  const [referID, setreferID] = useState("");

  console.log(referName);
  const hendlereferCheck = async (e) => {
    setreferID(e);
    try {
      // let uppercaseStr = loginData?.phone.toUpperCase();
      const response = await Api.post(`${URL.referCheck}`, {
        referBy: e?.toUpperCase(),
      });
      if (response?.data?.success) {
        setreferName(response?.data?.data?.fullName);
      } else {
        setreferName("");
        // toast.error("invalid refer code");
      }
    } catch (error) {
      console.log(error);
      toast.error("invalid refer code");
    }
  };
  var settings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const hendletochange = (e) => {
    const { name, value } = e?.target;
    setuserData({ ...userData, [name]: value });
  };

  const hendletoSubmit = async (e) => {
    e.preventDefault();

    if (!userData?.fullName) {
      toast.error("please enter full name");
    } else if (!userData?.phone) {
      toast.error("please enter phone");
    } else {
      const response = await Api.post(`${URL.registerUser}`, {
        fullName: userData?.fullName,
        phone: userData?.phone,
      });

      if (response?.data?.success) {
        // setotpBox("2");
        route("/login");
        toast.success(response?.data?.message);
      } else {
        toast.error(response?.data?.message);
      }
    }
  };

  const [stateselect, setstateselect] = useState(null);
  const [districtselect, setdistrictselect] = useState(null);
  const [cityselect, setcityselect] = useState(null);

  const [mangeKey, setmangeKey] = useState(0);
  const [mangeKey2, setmangeKey2] = useState(0);

  useEffect(() => {
    setmangeKey(mangeKey + 1);
    setmangeKey2(mangeKey2 + 1);
    setdistrictselect(null);
    setcityselect(null);

    return () => {};
  }, [stateselect]);
  useEffect(() => {
    // setmangeKey(mangeKey + 1);
    setmangeKey2(mangeKey2 + 1);
    setcityselect(null);
    return () => {};
  }, [districtselect]);

  const loadOptionsState = async (search, loadedOptions, pages) => {
    console.log(loadedOptions);
    console.log(pages);
    const response = await fetch(
      URL.API_BASE_URL +
        `/publicApi/searchareaStates?key=${search}&page=${pages?.page}`
    );
    const responseJSON = await response.json();

    const options = responseJSON?.results?.map((data) => {
      data.value = data?._id;
      data.label = data?.State;

      return data;
    });

    return {
      options: options,
      hasMore: responseJSON.has_more,
      additional: {
        page: pages?.page + 1,
      },
    };
  };
  const loadOptionsDisrcit = async (search, loadedOptions, pages) => {
    console.log(loadedOptions);
    console.log(pages);
    const response = await fetch(
      URL.API_BASE_URL +
        `/publicApi/searchareaDistricts?key=${search}&page=${pages?.page}&States_id=${stateselect?.State}`
    );
    const responseJSON = await response.json();

    const options = responseJSON?.results?.map((data) => {
      data.value = data?._id;
      data.label = data?.District;

      return data;
    });

    return {
      options: options,
      hasMore: responseJSON.has_more,
      additional: {
        page: pages?.page + 1,
      },
    };
  };

  const loadOptionsCity = async (search, loadedOptions, pages) => {
    console.log(loadedOptions);
    console.log(pages);
    const response = await fetch(
      URL.API_BASE_URL +
        `/publicApi/searchareacity?key=${search}&page=${pages?.page}&District_id=${districtselect?.District}`
    );
    const responseJSON = await response.json();

    const options = responseJSON?.results?.map((data) => {
      data.value = data?._id;
      data.label = data?.Area + ", " + data?.Pincode;

      return data;
    });

    return {
      options: options,
      hasMore: responseJSON.has_more,
      additional: {
        page: pages?.page + 1,
      },
    };
  };

  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [error, setError] = useState(null);

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);

          // Send the location to your server
          // axios
          //   .post("http://localhost:3000/location", {
          //     latitude: position.coords.latitude,
          //     longitude: position.coords.longitude,
          //   })
          //   .then((response) => {
          //     console.log(response.data);
          //   })
          //   .catch((error) => {
          //     console.error("There was an error sending the location!", error);
          //   });
        },
        (error) => {
          setError(error.message);
        }
      );
    } else {
      setError("Geolocation is not supported by this browser.");
    }
  };

  const hendleverifysec = async (e) => {
    e?.preventDefault();

    if (club == "left" || club == "right") {
      if (!userData?.fullName) {
        toast.error("Full Name Required");
      } else if (!userData?.phone) {
        toast.error("please enter phone");
      } else if (!userData?.email) {
        toast.error("Email Required");
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(userData?.email)
      ) {
        toast.error("Email  Invalid");
      } else if (!userData?.confirmPassword) {
        toast.error("Confirm Password Required");
      } else if (!userData?.password) {
        toast.error("Password Required");
      } else if (userData?.password !== userData?.confirmPassword) {
        toast.error("Password not Match");
      } else if (!cityselect?.Area) {
        toast.error("please enter city");
      } else if (!stateselect?.State) {
        toast.error("please enter state");
      } else if (!cityselect?.Pincode) {
        toast.error("please enter zip");
      } /* else if (!userData?.side) {
      toast.error("Please select club");
    }  */ else {
        // userData.referBy = referID;
        // referID
        // otpVerifyuser
        const fainaldata = {
          fullName: userData?.fullName,
          phone: userData?.phone,
          password: userData?.password,
          district: districtselect?.District,
          city: cityselect?.Area,
          state: stateselect?.State,
          zip: cityselect?.Pincode,
          // zip: cityselect?.Pincode,
          location_id: cityselect?._id,
          country: userData?.country,
          email: userData?.email,
          displayName: userData?.displayName,
          role_name: type,
          side: club,
          // longitude: longitude,
          // latitude: latitude,
          referBy: referID,
          otp: userData?.otp,
        };
        if (longitude) {
          fainaldata.longitude = longitude;
        }
        if (latitude) {
          fainaldata.latitude = latitude;
        }
        const response = await Api.post(`${URL.otpVerifyuser}`, fainaldata);
        if (response?.data?.success) {
          // setotpBox("1");
          // setregisterDetails(response?.data?.data);
          route("/login");
          toast.success(response?.data?.message);
        } else {
          toast.error(response?.data?.message);
        }
        // dispatch(allapiAction.signupSeller(userData));
        // return () => {};
      }
    } else {
      toast.error("club invalid");
    }
  };

  useEffect(() => {
    dispatch(allapiAction.getsliderList({ page: "registerpage" }));

    return () => {};
  }, []);
  return (
    <div>
      <div className="hometest">
        <RegisaterSlider sliderlist={slider} />
      </div>
      <div className="container">
        <div className="card p-5  ">
          <div className="row">
            <div className="col-sm-12 col-12">
              {/* <ul className="ps-tab-list">
                <li className={type == "User" ? "active" : ""}>
                  <a
                    href="#"
                    onClick={() => {
                      settype("User");
                    }}
                  >
                    User
                  </a>
                </li>
                <li className={type == "Vendor" ? "active" : ""}>
                  <a
                    href="#"
                    onClick={() => {
                      settype("Vendor");
                    }}
                  >
                    Vendor
                  </a>
                </li>
              </ul> */}
              {type == "User" ? (
                <h2 className="text-center">Customer Registration</h2>
              ) : (
                <h2 className="text-center">Vendor Registration</h2>
              )}

              {otp_box == "1" ? (
                <div className="card p-4">
                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <label>Full Name</label>
                        <input
                          type=""
                          className="form-control"
                          name="fullName"
                          value={userData?.fullName}
                          onChange={(e) => {
                            hendletochange(e);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label>Number</label>
                        <input
                          type="number"
                          className="form-control"
                          name="phone"
                          value={userData?.phone}
                          onChange={(e) => {
                            hendletochange(e);
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group submtit">
                    <button
                      onClick={(e) => {
                        hendletoSubmit(e);
                      }}
                      className="ps-btn ps-btn--fullwidth"
                    >
                      Send otp
                    </button>
                  </div>
                </div>
              ) : otp_box == "2" ? (
                <>
                  <h1>Wellcome message show krna hai </h1>
                </>
              ) : (
                <form
                  // action=""
                  className="ps-form--account2 ps-tab-root registerfm"
                >
                  <ul className="ps-tab-list">
                    <li
                      onClick={() => {
                        settype("User");
                      }}
                      className={
                        type == "User" ? "active tbbtn gradient-bg" : "tbbtn"
                      }
                    >
                      <a href="#">Customer</a>
                    </li>
                    {/* <li
                      className={
                        type == "Vendor" ? "active tbbtn gradient-bg" : " tbbtn"
                      }
                      onClick={() => {
                        settype("Vendor");
                      }}
                    >
                      <a href="#">Vendor</a>
                    </li> */}
                  </ul>
                  <div className="  ">
                    <div className="ps-tab active">
                      <div className="row">
                        <div className=" col-sm-6 col-12">
                          <div class="input-box2">
                            <span class="icon">
                              <ion-icon name="lock-closed"></ion-icon>
                            </span>
                            <label> Refer Code Name</label>
                            <input
                              type="text"
                              required
                              className="form-control"
                              name="ref"
                              value={ref}
                              //   onChange={(e) => {
                              //     hendletochange(e);
                              //   }}
                            />
                          </div>
                        </div>
                        <div className=" col-sm-6 col-12">
                          <div class="input-box2">
                            <span class="icon">
                              <ion-icon name="mail"></ion-icon>
                            </span>
                            <label> Refered by</label>
                            <input
                              type="text"
                              required
                              className="form-control"
                              name="referName"
                              value={referName}
                              //   onChange={(e) => {
                              //     hendletochange(e);
                              //   }}
                            />
                          </div>
                        </div>
                        <div className=" col-sm-6 col-12">
                          <div class="input-box2">
                            <span class="icon">
                              <ion-icon name="lock-closed"></ion-icon>
                            </span>
                            <label>Full Name</label>
                            <input
                              type="text"
                              required
                              className="form-control"
                              name="fullName"
                              value={userData?.fullName}
                              onChange={(e) => {
                                hendletochange(e);
                              }}
                            />
                          </div>
                        </div>
                        <div className=" col-sm-6 col-12">
                          <div class="input-box2">
                            <span class="icon">
                              <ion-icon name="mail"></ion-icon>
                            </span>
                            <label>Phone</label>
                            <input
                              type="number"
                              required
                              className="form-control"
                              name="phone"
                              value={userData?.phone}
                              onChange={(e) => {
                                hendletochange(e);
                              }}
                            />
                          </div>
                        </div>

                        <div className=" col-sm-6 col-12">
                          <div class="input-box2">
                            <span class="icon">
                              <ion-icon name="mail"></ion-icon>
                            </span>
                            <label>Email</label>
                            <input
                              type="email"
                              required
                              name="email"
                              className="form-control"
                              value={userData?.email}
                              onChange={(e) => {
                                hendletochange(e);
                              }}
                            />
                          </div>
                        </div>
                        {type !== "User" && (
                          <div className=" col-sm-6 col-12">
                            <div class="input-box2">
                              <span class="icon">
                                <ion-icon name="mail"></ion-icon>
                              </span>
                              <label>Display Name</label>
                              <input
                                type="text"
                                // required
                                name="displayName"
                                className="form-control"
                                value={userData?.displayName}
                                onChange={(e) => {
                                  hendletochange(e);
                                }}
                              />
                            </div>
                          </div>
                        )}
                        <div className=" col-sm-6 col-12">
                          <div class="input-box2">
                            <span class="icon">
                              <ion-icon name="mail"></ion-icon>
                            </span>
                            <label>Password</label>
                            <input
                              type="password"
                              required
                              name="password"
                              className="form-control"
                              value={userData?.password}
                              onChange={(e) => {
                                hendletochange(e);
                              }}
                            />
                          </div>
                        </div>
                        <div className=" col-sm-6 col-12">
                          <div class="input-box2">
                            <span class="icon">
                              <ion-icon name="mail"></ion-icon>
                            </span>

                            <label>Confirm Password</label>
                            <input
                              type="password"
                              required
                              name="confirmPassword"
                              className="form-control"
                              value={userData?.confirmPassword}
                              onChange={(e) => {
                                hendletochange(e);
                              }}
                            />
                          </div>
                        </div>
                        <div className=" col-sm-6 col-12">
                          <div class="input-box2">
                            <span class="icon">
                              <ion-icon name="mail"></ion-icon>
                            </span>
                            <label>Country</label>
                            <input
                              type="text"
                              required
                              name="country"
                              className="form-control"
                              value={userData?.country}
                              // onChange={(e) => {
                              //   hendletochange(e);
                              // }}
                            />
                          </div>
                        </div>
                        <div className=" col-sm-6 col-12">
                          <div class="input-box2">
                            <span class="icon">
                              <ion-icon name="mail"></ion-icon>
                            </span>
                            <label>State</label>
                            {/* <input
                type="text"
                required
                name="state"
                value={userData?.state}
                onChange={(e) => {
                  hendletochange(e);
                }}
              /> */}

                            <AsyncPaginate
                              value={stateselect}
                              className="liceninput"
                              placeholder="State"
                              loadOptions={loadOptionsState}
                              onChange={setstateselect}
                              additional={{
                                page: 1,
                              }}
                            />

                            {/* <select
                id=""
                className=""
                onChange={(e) => {
                  setState(e?.target?.value);
                }}
              >
                <option value={""}>Select State</option>

                {statesindia &&
                  statesindia?.map((data, i) => {
                    return (
                      <option key={i} value={data?.name}>
                        {data?.name}
                      </option>
                    );
                  })}
              </select> */}
                          </div>
                        </div>
                        <div className=" col-sm-6 col-12">
                          <div class="input-box2">
                            <label>District</label>
                            <AsyncPaginate
                              key={mangeKey}
                              value={districtselect}
                              className="liceninput"
                              placeholder="District"
                              loadOptions={loadOptionsDisrcit}
                              onChange={setdistrictselect}
                              additional={{
                                page: 1,
                              }}
                            />
                            {/* <span class="icon">
                <ion-icon name="mail"></ion-icon>
              </span>
              <input
                type="text"
                required
                name="district"
                value={userData?.district}
                onChange={(e) => {
                  hendletochange(e);
                }}
              /> */}
                          </div>
                        </div>
                        <div className=" col-sm-6 col-12">
                          <div class="input-box2">
                            <span class="icon">
                              <ion-icon name="mail"></ion-icon>
                            </span>
                            {/* <input
                type="text"
                required
                name="city"
                value={userData?.city}
                onChange={(e) => {
                  hendletochange(e);
                }}
              /> */}
                            <label>City</label>
                            <AsyncPaginate
                              key={mangeKey2}
                              value={cityselect}
                              className="liceninput"
                              placeholder="City"
                              loadOptions={loadOptionsCity}
                              onChange={setcityselect}
                              additional={{
                                page: 1,
                              }}
                            />
                          </div>
                        </div>
                        {/* <div className=" col-sm-6 col-12">
                          <div class="input-box2">
                            <span class="icon">
                              <ion-icon name="lock-closed"></ion-icon>
                            </span>
                            <label>Select Club</label>
                            <select
                              id=""
                              className="form-control"
                              name="side"
                              onChange={(e) => {
                                hendletochange(e);
                              }}
                            >
                              <option value={""}>Select Club</option>
                              <option value={"left"}>Left</option>
                              <option value={"right"}>Right</option>
                            </select>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>

                  {/* <div class="input-box2">
              <span class="icon">
                <ion-icon name="lock-closed"></ion-icon>
              </span>
              <input
                type="number"
                required
                name="otp"
                value={userData?.otp}
                onChange={(e) => {
                  hendletochange(e);
                }}
              />
              <label>Otp</label>
            </div> */}

                  {/* <div class="input-box2">
              <span class="icon">
                <ion-icon name="mail"></ion-icon>
              </span>
              <input
                type="text"
                required
                name="zip"
                value={userData?.zip}
                onChange={(e) => {
                  hendletochange(e);
                }}
              />
              <label>Zip</label>
            </div> */}

                  {/* <button
                    type="submit"
                    onClick={(e) => {
                      hendleverifysec(e);
                    }}
                  >
                    Submit
                  </button> */}
                  <div class="mt-15"></div>
                  <div class="form-group submtit">
                    <button
                      class="ps-btn ps-btn--fullwidth"
                      onClick={(e) => {
                        hendleverifysec(e);
                      }}
                    >
                      Register
                    </button>
                  </div>

                  {/* <div class="register-link">
                    <p>
                      You have an account? <Link to="/login">Login</Link>
                    </p>
                  </div> */}
                </form>
              )}
            </div>
            {/* <div className="col-sm-6">
              <div className="ps-section__right row">
                <div className="col-sm-12 col-12 adsboxcus1">
                  {ad && (
                    <Slider {...settings}>
                      {ad &&
                        ad?.map((data, i) => {
                          return (
                            <div key={i}>
                              <a className="ps-collection" href="#">
                                <img
                                  src={URL?.API_BASE_URL + data?.image}
                                  alt=""
                                />
                              </a>
                            </div>
                          );
                        })}
                    </Slider>
                  )}
                </div>
                <div className="col-sm-12 col-12 adsboxcus1">
                  {ads2 && (
                    <Slider {...settings}>
                      {ads2 &&
                        ads2?.map((data, i) => {
                          return (
                            <div key={i}>
                              <a className="ps-collection" href="#">
                                <img
                                  src={URL?.API_BASE_URL + data?.image}
                                  alt=""
                                />
                              </a>
                            </div>
                          );
                        })}
                    </Slider>
                  )}
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      <div className="hometest">
        <RegisaterSlider sliderlist={ads2} />
      </div>
    </div>
  );
}

export default RefRegisterComponent;
