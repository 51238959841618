// import { URL } from "@/Redux/common/url";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import { URL } from "../../Redux/common/url";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Cmimg from "./Cmimg";

function HomeSlider({ homedata }) {
  const slider_list = useSelector((state) =>
    state?.allapi?.slider_list ? state?.allapi?.slider_list : {}
  );

  const slider = slider_list?.sliders ? slider_list?.sliders : [];
  const ad = slider_list?.ads ? slider_list?.ads : [];
  const banner = slider_list?.banner ? slider_list?.banner : [];
  const ads2 = [...ad].reverse();

  var settings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,

    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // cssEase: "ease-in-out",
    // fade: true,
  };
  const [isLoaded, setIsLoaded] = useState(false);
  return (
    <div>
      <div className="ps-home-banner ps-home-banner--1">
        <div className="ps-container">
          <div className="ps-section__left">
            {slider && (
              <Slider {...settings}>
                {slider &&
                  slider?.map((data, i) => {
                    return (
                      <div className="ps-banner bg--cover" key={i}>
                        <a className="ps-banner__overlay" href="#">
                          {/* <img
                            src={URL?.API_BASE_URL + data?.image}
                            alt={"slider" + i}
                              loading="lazy"
                              // style={{ filter: 'blur(20px)', transition: 'filter 0.3s ease-in-out' }}
                          /> */}

                          <Cmimg
                            url={URL?.API_BASE_URL + data?.image}
                            altkey={"slider" + i}
                          />
                          
                          {/* <img
                            src={URL?.API_BASE_URL + data?.image}
                            alt={"slider" + i}
                            className={`blur-image ${isLoaded ? "hide" : ""}`}
                            style={{
                              filter: "blur(20px)",
                              transition: "opacity 0.3s ease-in-out",
                              opacity: isLoaded ? 0 : 1,
                            }}
                          />
                          <LazyLoadImage
                            alt={"slider" + i}
                            // width={imageWidth}
                            // height={imageHeight}
                            delayTime={300}
                            onLoad={() => setIsLoaded(true)}
                            style={{
                              // Hide until loaded
                              transition: "opacity 0.3s ease-in-out",
                              opacity: isLoaded ? 1 : 0,
                            }}
                            src={URL?.API_BASE_URL + data?.image} // use normal <img> attributes as props
                          /> */}
                        </a>
                      </div>
                    );
                  })}
              </Slider>
            )}
          </div>
          {/* <div className="ps-section__right row">
            <div className="col-sm-12 col-6 adsboxcus">
              {ad && (
                <Slider {...settings}>
                  {ad &&
                    ad?.map((data, i) => {
                      return (
                        <div key={i}>
                          <a className="ps-collection" href="#">
                            <img src={URL?.API_BASE_URL + data?.image} alt="" />
                          </a>
                        </div>
                      );
                    })}
                </Slider>
              )}
            </div>
            <div className="col-sm-12 col-6 adsboxcus">
              {ads2 && (
                <Slider {...settings}>
                  {ads2 &&
                    ads2?.map((data, i) => {
                      return (
                        <div key={i}>
                          <a className="ps-collection" href="#">
                            <img src={URL?.API_BASE_URL + data?.image} alt="" />
                          </a>
                        </div>
                      );
                    })}
                </Slider>
              )}
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default HomeSlider;
