import axios from "axios";
// import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { URL } from "./url";
import { useNavigate } from "react-router-dom";
// const navigate = useNavigate();

let Api = axios.create({
  baseURL: URL.API_BASE_URL,
  // headers: localStorage.getItem('token') ? {'Authorization': `Bearer ${localStorage.getItem('token')}`} : undefined
});

Api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status == 401) {
      // localStorage.clear();
      // window.location.href = "/";
  
    }

    // Do something with response error
    return Promise.reject(error);
  }
);
export default Api;
